document.addEventListener('turbolinks:load', function () {
  const navbarColorInput = document.getElementById('nav_color');
  const navbarCustomColor = document.getElementById('navbar_custom_color');
  const buttonColorInput = document.getElementById('button_color');
  const buttonCustomColor = document.getElementById('button_custom_color');

  // Initialize custom picker colors if the elements exist
  if (navbarCustomColor && navbarColorInput) {
    navbarCustomColor.style.backgroundColor = navbarColorInput.value;
  }

  if (buttonCustomColor && buttonColorInput) {
    buttonCustomColor.style.backgroundColor = buttonColorInput.value;
  }

  // Sync custom picker with native color picker
  if (navbarColorInput) {
    navbarColorInput.addEventListener('input', function () {
      if (navbarCustomColor) {
        navbarCustomColor.style.backgroundColor = this.value;
      }
    });
  }

  if (buttonColorInput) {
    buttonColorInput.addEventListener('input', function () {
      if (buttonCustomColor) {
        buttonCustomColor.style.backgroundColor = this.value;
      }
    });
  }

  // Trigger native color picker when clicking on custom picker
  if (navbarCustomColor) {
    navbarCustomColor.addEventListener('click', function () {
      navbarColorInput && navbarColorInput.click();
    });
  }

  if (buttonCustomColor) {
    buttonCustomColor.addEventListener('click', function () {
      buttonColorInput && buttonColorInput.click();
    });
  }
});

function resetPreference(currentPreferenceId, accountId) {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

  fetch(`/${accountId}/preferences/${currentPreferenceId}/reset_preference`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
    },
    body: JSON.stringify({}),
  })
    .then(response => {
      if (!response.ok) throw new Error('Network response was not ok');
      return response.json();
    })
    .then(data => updateUI(data))
    .catch(error => console.error("Something went wrong", error));
}

// Function to update the UI based on the response
function updateUI(data) {
  const preferenceElement = document.getElementById("app-top");
  const buttonSelectors = [
    '.btn',
    '.button',
    'button',
    '[type="button"]',
    '[type="reset"]',
    '[type="submit"]',
  ].join(', ');

  // Update preference element background color
  if (preferenceElement) {
    const navbarCustomColor = document.getElementById('navbar_custom_color');
    navbarCustomColor.style.backgroundColor = data.preference.navbar_color;
    preferenceElement.style.backgroundColor = data.preference.navbar_color;
  }

  // Update button colors
  const buttons = document.querySelectorAll(buttonSelectors);
  const buttonCustomColor = document.getElementById('button_custom_color');

  buttonCustomColor.style.backgroundColor = data.preference.button_color;
  buttons.forEach(button => {
    button.style.background = data.preference.button_color;
  });
}

// Attach the resetPreference function to the global scope
window.resetPreference = resetPreference;
