import { Controller } from 'stimulus'

export default class extends Controller {

  static targets = ['dragContainer']

  connect() {
    var dragula = require('dragula');
    
    this.drake = dragula({
      containers: Array.from(this.dragContainerTargets),
      moves: (el, container, handle) => handle.classList.contains('drag-icons-dots'),
      invalid: (el, handle) => el.classList.contains('no-drag')
    });

    this.drake.on('drop', this.changePosition.bind(this))
  }

  changePosition(el, target, source, sibling) {
    var processTemplateId = this.element.getAttribute("data-process-template-id");
    var elementId = el.getAttribute("data-step-id")
    var siblingId = null;

    if(sibling !== null) {
      siblingId = sibling.getAttribute("data-step-id")
    }
    let data = { id: elementId, sibling_id: siblingId };

    fetch(`/${accountId}/process_templates/${processTemplateId}/step_templates/${elementId}/update_position`, {
      method: "PATCH",
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      if (!response.ok) {
        throw response
      }
      return response.json();
    }).then((responseJson) => {
      if(responseJson.sibling_present === true) {
        sibling = el.previousElementSibling
      }

      let elStepSettings = el.querySelector(".meta-item.step-settings-item");
      let siblingStepSettings = sibling.querySelector(".meta-item.step-settings-item");
      elStepSettings.innerHTML = responseJson.current_task_setting;
      siblingStepSettings.innerHTML = responseJson.sibling_task_setting;
    }).catch((error) => {
      console.log(error)
    })
  }

}