import { Controller } from "stimulus";
import SlimSelect from 'slim-select';

export default class extends Controller {
  connect() {
    console.log("Look-up controller connected");
    
    // Initialize SlimSelect for all .slim-select elements globally
    this.initializeGlobalSlimSelect();
  }

  toggle(event) {
    console.log("Toggle action triggered", event.target.checked);
  }

  // Initialize SlimSelect for all .slim-select elements across the application
  initializeGlobalSlimSelect() {
    const globalSelectElements = document.querySelectorAll('select.slim-select');
    
    globalSelectElements.forEach(selectElement => {
      if (!selectElement.slimSelectInstance) {
        this.initializeSlimSelectForElement(selectElement);
      }
    });
  }

  // Initialize SlimSelect for a specific select element
  initializeSlimSelectForElement(selectElement) {
    if (!selectElement) {
      console.error("Select element not found");
      return;
    }

    // Check if SlimSelect is already initialized for this element
    selectElement.slimSelectInstance = new SlimSelect({
      select: selectElement,
      settings: {
        closeOnSelect: false,
        allowDeselect: true
      },
    });

    console.log("SlimSelect initialized for element:", selectElement);
  }
}