import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['editor', 'dropdown']
  initialize() {
    this.selectionRange = null;
    this.dropdownFocused = false;
  }

  connect() {
    this.selectionRange = null;

    // Access the Trix editor target using the data attribute
    this.editorTarget //= document.querySelector(`[trix-id='trix-block-${this.element.dataset.blockIdValue}']`);

    if (!this.editorTarget) {
      console.error(`Trix editor not found for trix-id: trix-block-${this.editorTarget}`);
      return;
    }

    // Store the selection range on Trix editor interaction
    this.editorTarget.addEventListener("trix-selection-change", this.handleSelectionChange.bind(this));

    // Prevent selection change when interacting with the dropdown

    this.dropdownTarget.addEventListener('mousedown', () => {
      this.dropdownFocused = true;
      console.log("Dropdown focused, preventing selection reset.");
      this.storeSelectionRange();  // Store the selection range before dropdown interaction
    });

    this.dropdownTarget.addEventListener('blur', () => {
      this.dropdownFocused = false;
      console.log("Dropdown blurred, allowing selection updates.");
    });

    // Store selection range on editor mouseup event
    this.editorTarget.addEventListener('mouseup', this.storeSelectionRange.bind(this));

    // Restore the selection range from localStorage if it exists
    const savedRange = this.loadSelectionRange();
    if (savedRange && savedRange[0] !== 0) {
      this.selectionRange = savedRange;
      console.log("Restored selection range from localStorage:", this.selectionRange);
    }
  }

  handleSelectionChange(event) {
    if (!this.dropdownFocused) {
      // Store the selection range only if the dropdown is not focused
      this.storeSelectionRange(event);
    } else {
      console.log("Ignoring selection change due to dropdown focus.");
    }
  }

  storeSelectionRange(event) {
    const editor = this.editorTarget.editor;
    if (editor) {
      const currentRange = editor.getSelectedRange();
      if (currentRange[0] !== 0 && currentRange[1] !== 0) {
        this.selectionRange = currentRange;
        console.log("Storing valid selection range:", this.selectionRange);

        // Save the selection range to localStorage
        this.saveSelectionRange(this.selectionRange);
      } else {
        console.log("Ignoring invalid selection range:", currentRange);
      }
    }
  }

  insertTag(event) {
    const selectedTag = event.target.value; // Get the selected tag value
    console.log("Insert tag event triggered with selected tag:", selectedTag);

    const editorElement = this.editorTarget;
    if (!editorElement) {
      console.error("Failed to access Trix editor from the editor element.");
      return;
    }

    const editor = editorElement.editor;

    // Restore the selection range from localStorage if available
    const savedRange = this.loadSelectionRange();
    if (savedRange && savedRange[0] !== 0) {
      editor.setSelectedRange(savedRange);
      console.log("Restored selection range from localStorage:", savedRange);
    } else if (this.selectionRange && this.selectionRange[0] !== 0) {
      editor.setSelectedRange(this.selectionRange);
      console.log("Restored selection range:", this.selectionRange);
    } else {
      console.log("No valid selection range was stored.");
    }

    // Insert the tag at the current selection range
    editor.insertString(selectedTag);

    // Store the new selection range after inserting the tag
    this.selectionRange = editor.getSelectedRange();
    console.log("New selection range after inserting tag:", this.selectionRange);

    // Save the updated range to localStorage
    this.saveSelectionRange(this.selectionRange);

    // Reset dropdown value after insertion
    event.target.value = '';
  }

  // Save the selection range to localStorage
  saveSelectionRange(range) {
    localStorage.setItem('trixSelectionRange', JSON.stringify(range));
  }

  // Load the selection range from localStorage
  loadSelectionRange() {
    const range = localStorage.getItem('trixSelectionRange');
    return range ? JSON.parse(range) : null;
  }
}
