// app/javascript/channels/update_position_channel.js
import consumer from "./consumer";

document.addEventListener("turbolinks:load", () => {
  const listContainer = document.querySelector(".steps[data-list-id]");
  const listId = listContainer?.dataset.listId;
  const channelName = "UpdatePositionChannel";

  if (!listId) {
    console.log("No list_id found, subscription not created.");
    return;
  }

  const subscription = consumer.subscriptions.create(
    { channel: channelName, list_id: listId },
    {
      connected() {
        console.log(`Connected to ${channelName}`);
      },
      disconnected() {
        console.log(`Disconnected from ${channelName}`);
      },
      received(data) {
        const listContainer = document.querySelector(`.steps[data-list-id="${listId}"]`);
        if (!listContainer) {
          console.error("List container not found.");
          return;
        }

        const itemElements = Array.from(listContainer.children).reduce((elements, item) => {
          const itemId = item.getAttribute("data-item-id");
          if (itemId) elements[itemId] = item;
          return elements;
        }, {});

        // Sort and reinsert each item based on position data
        data.ordered_list_positions.forEach(({ id, position }) => {
          const itemElement = itemElements[id];
          if (!itemElement) return;

          itemElement.remove();
          if (position >= listContainer.children.length) {
            listContainer.appendChild(itemElement); // Append at the end
          } else {
            listContainer.insertBefore(itemElement, listContainer.children[position]);
          }
        });
      }
    }
  );
});
